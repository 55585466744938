/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&family=Poppins:wght@100;400&display=swap");

@font-face {
    font-family: 'calibriregular';
    src: url('./assets/fonts/calibri-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/calibri-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dinregular';
    src: url('./assets/fonts/din-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/din-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'tgl';
    src: url('./assets/fonts/tgl_0-1451eng-webfont.woff2') format('woff2'),
         url('./assets/fonts/tgl_0-1451eng-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
    border: 0;
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
html,
body {
    scroll-behavior: smooth;
}
body {
    background-color: #000;
    font-family: "dinregular", "tgl", "Poppins", sans-serif;
    font-size: 18px;
}
.justify {
    text-align: justify;
}